import * as React from "react";

const StackedCardsLeft = ({ children }) => {
  return (
    <div className="relative my-3 sm:mx-auto">
      <div className="absolute inset-0 bg-gradient-to-r from-rose-400 to-amber-200 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
      <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
        <div className="">
          <div className="divide-y divide-gray-200">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default StackedCardsLeft